<!--
 * @Author: your name
 * @Date: 2021-11-11 11:46:48
 * @LastEditTime: 2021-11-11 14:39:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\team-building\safety-train\index.vue
-->
<template>
  <div>
    <dynamic-switching @switch-display="switchDisplay" />
    <transition mode="out-in" name="component-transition">
      <component :is="componentData"></component>
    </transition>
  </div>
</template>
<script>
import DynamicSwitching from 'components/DynamicSwitching';
import ChartDisplay from './chart-display';
import ListDisplay from './list-display';
export default {
  components: {
    DynamicSwitching,
    ChartDisplay,
    ListDisplay
  },
  data() {
    return {
      componentData: 'ChartDisplay'
    };
  },
  methods: {
    switchDisplay(text) {
      //不用动态组件的话可以打印这个text的值，text的值是写死的(chart-display图表展示，list-display列表展示)
      this.componentData = text;
    }
  }
};
</script>
